<template>
	<div id="app" :style="`backgroundImage:url(${globalBg})`">
		<keep-alive include="indexPage,yingyuPublicPage,danCiSuJiPageCancel">
			<router-view />
		</keep-alive>
		<!-- <div class="webInfs">
			<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2022024380号</a>
			Copyright ©2021 京点未来教育研究院 版权所有
		</div> -->

		<audio
			:src="errorVoice"
			controlslist="nodownload"
			controls="controls"
			ref="errorAudio"
			class="voice-dom"
		></audio>
		<audio
			:src="properVoice"
			controlslist="nodownload"
			controls="controls"
			ref="properAudio"
			class="voice-dom"
		></audio>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'App',
	computed: {
		...mapGetters({
			isFullScreen: 'common/getFullScreen'
		})
	},
	watch: {
		$route: function (val) {}
	},
	data() {
		return {
			globalBg: require('@/assets/image/bg.png'),
			fullFlag: false,
			errorVoice: require('@/assets/audio/error.mp3'),
			properVoice: require('@/assets/audio/proper.wav'),
			errorAudio: null,
			properAudio: null
		};
	},
	created() {
		// var is360 = _mime("type", "application/vnd.chromium.remoting-viewer");
		// if (isChrome() && !is360) {
		// this.$message({
		// type: "warning",
		// message: "为了有更好的使用体验，请使用360浏览器！",
		// duration:0,
		// showClose:true
		// });
		// }
		function isChrome() {
			var ua = navigator.userAgent.toLowerCase();

			return ua.indexOf('chrome') > 1;
		}
		function _mime(option, value) {
			var mimeTypes = navigator.mimeTypes;
			for (var mt in mimeTypes) {
				if (mimeTypes[mt][option] == value) {
					return true;
				}
			}
			return false;
		}
	},
	mounted() {
		this.errorAudio = this.$refs['errorAudio'];
		this.properAudio = this.$refs['properAudio'];

		this.$eventBus.$on('playError', () => {
			this.errorAudio.currentTime = 0;
			this.properAudio.currentTime = 0;
			this.errorAudio.play();
		});
		this.$eventBus.$on('playProper', () => {
			this.errorAudio.currentTime = 0;
			this.properAudio.currentTime = 0;
			this.properAudio.play();
		});
		window.addEventListener('resize', () => {
			this.fullFlag = !this.fullFlag;
		});

		window.addEventListener('dragstart', (e) => {
			let localName = e.target.localName;
			if (localName == 'img') {
				e.preventDefault();
			}
		});
	},
	destroyed() {},
	methods: {}
};
</script>

<style lang="scss">
@import 'assets/css/iconfont.css';
@import 'assets/css/baseStyle.scss';
.el-message-box__message p {
	font-size: 0.2rem;
}
.el-message-box__btns {
	.el-button {
		font-size: 0.16rem;
		font-weight: normal;
	}
}

.new-labal-box {
	padding-right: 0.04rem;
	display: inline-flex;
}

.el-message__icon {
	font-size: 0.4rem;
}
.el-message-box__message {
	font-size: 0.16rem;
	padding: 0.2rem 0;
	text-align: center;
}
.bscroll-indicator {
	background: #dcdcdc !important;
}
</style>
<style lang="scss" scoped>
.webInfs {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0.15rem;
	font-size: 0.18rem;
	color: #fff;
	text-align: center;
	a {
		color: #fff;
		text-decoration: none;
	}
}
.voice-dom {
	position: fixed;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	overflow: hidden;
	visibility: hidden;
}

#app {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	overflow: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #333;
	user-select: text;
	transform-origin: center;
}
</style>
