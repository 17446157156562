import Vue from "vue";
import VueRouter from "vue-router";
import index from "@/views/index/index.vue";

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
//重写push方法
VueRouter.prototype.push = function (location, res, rej) {
  if (res && rej) {
    originPush.call(this, location, res, rej);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
//重写replace方法
VueRouter.prototype.replace = function (location, res, rej) {
  if (res && rej) {
    originReplace.call(this, location, res, rej);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: index,
    children: [
      {
        path: "/",
        name: "mainIndex",
        component: () => import("@/views/index/mainIndex/index.vue"),
      },
      {
        path: "/library",
        name: "library",
        component: () => import("@/views/index/library/index.vue"),
      },
      {
        path: "/errorBook",
        name: "errorBook",
        component: () => import("@/views/index/errorBook/index.vue"),
      },
      {
        path: "/userCenter",
        name: "userCenter",
        component: () => import("@/views/index/userCenter/index.vue"),
        children: [
          {
            path: "/",
            name: "version",
            component: () => import("@/views/index/userCenter/version.vue"),
          },
          {
            path: "/afterSale",
            name: "afterSale",
            component: () => import("@/views/index/userCenter/afterSale.vue"),
          },
          {
            path: "/changePassword",
            name: "changePassword",
            component: () =>
              import("@/views/index/userCenter/changePassword.vue"),
          },
          {
            path: "/history",
            name: "history",
            component: () => import("@/views/index/userCenter/history.vue"),
          },
          {
            path: "/userAudio",
            name: "userAudio",
            component: () => import("@/views/index/userCenter/userAudio.vue"),
          },
          {
            path: "/userInfs",
            name: "userInfs",
            component: () => import("@/views/index/userCenter/userInfs.vue"),
          },{
            path: "/barEchart",
            name: "barEchart",
            component: () => import("@/views/index/userCenter/barEchart.vue"),
          },
        ],
      },
    ],
  },

  {
    path: "/libraryBook",
    name: "libraryBook",
    component: () => import("@/views/index/library/libraryBook.vue"),
  },
	
  {
    path: "/moudle",
    name: "moudle",
    component: () => import("@/views/moudle/index.vue"),
    children: [
      {
        path: "/zhiShiQuanJie",
        name: "zhiShiQuanJie",
        component: () => import("@/views/moudle/zhiShiQuanJie.vue"),
      },
      {
        path: "/shiYanTanJiu",
        name: "shiYanTanJiu",
        component: () => import("@/views/moudle/shiYanTanJiu.vue"),
      },

      
      {
        path: "/jiZhongJingXue",
        name: "jiZhongJingXue",
        component: () => import("@/views/moudle/jiZhongJingXue.vue"),
      },
      {
        path: "/zhuanXiangPeiYou",
        name: "zhuanXiangPeiYou",
        component: () => import("@/views/moudle/zhuanXiangPeiYou.vue"),
      },
      {
        path: "/customMoudle",
        name: "customMoudle",
        component: () => import("@/views/moudle/customMoudle.vue"),
      },
      
      {
        path: "/zhiJiGaoKao",
        name: "zhiJiGaoKao",
        component: () => import("@/views/moudle/zhiJiGaoKao.vue"),
      },
      {
        path: "/chapter",
        name: "chapter",
        component: () => import("@/views/moudle/chapter.vue"),
      },
      // 加上路由
      {
        path:'/innerChapter',
        name:'innerChapter',
        component:()=> import('@/views/moudle/innerChapter.vue')
      }
    ],
  },

  {
    path: "/publicPage",
    name: "publicPage",
    component: () => import("@/views/publicPage/index.vue"),
    children: [],
  },

  {
    path: "/yingyuPublicPage",
    name: "yingyuPublicPage",
    component: () => import("@/views/yingyuPublicPage/index.vue"),
    children: [],
  },

  {
    path: "/yingyuGPCHPublicPage",
    name: "yingyuGPCHPublicPage",
    component: () => import("@/views/yingyuGPCHPublicPage/index.vue"),
    children: [],
  },


  {
    path: "/liNianZhenTi",
    name: "liNianZhenTi",
    component: () => import("@/views/channel/liNianZhenTi/index.vue"),
    children: [],
  },
  {
    path: "/zhenTiContent",
    name: "zhenTiContent",
    component: () => import("@/views/channel/liNianZhenTi/zhenTiContent.vue"),
    children: [],
  },
  {
    path: "/errorView",
    name: "errorView",
    component: () => import("@/views/index/errorBook/errorView.vue"),
    children: [],
  },
  
  {
    path: "/qiZhongCeShi",
    name: "qiZhongCeShi",
    component: () => import("@/views/channel/qiZhongCeShi.vue"),
    children: [],
  },
  {
    path: "/qiMoCeShi",
    name: "qiMoCeShi",
    component: () => import("@/views/channel/qiMoCeShi.vue"),
    children: [],
  },
  {
    path: "/flashPage",
    name: "flashPage",
    component: () => import("@/views/channel/flashPage.vue"),
    children: [],
  },
  
  {
    path: "/danYuanJianCe",
    name: "danYuanJianCe",
    component: () => import("@/views/channel/danYuanJianCe.vue"),
    children: [],
  },
  {
    path: "/danYuanCeShi",
    name: "danYuanCeShi",
    component: () => import("@/views/channel/danYuanCeShi.vue"),
    children: [],
  },
  

  {
    path: "/wuLiChannel",
    name: "wuLiChannel",
    component: () => import("@/views/wuLiChannel/index.vue"),
    children: [      
      {
        path: "/wuLiTongBuKeTang",
        name: "wuLiTongBuKeTang",
        component: () => import("@/views/wuLiChannel/tongBuKeTang.vue"),
      }
    ],
  },

  {
    path: "/channel",
    name: "channel",
    component: () => import("@/views/channel/index.vue"),
    children: [
      {
        path: "/mingJiaLangDu",
        name: "mingJiaLangDu",
        component: () => import("@/views/channel/yuWen/mingJiaLangDu.vue"),
      },
      {
        path: "/wenXueChangShi",
        name: "wenXueChangShi",
        component: () => import("@/views/channel/yuWen/wenXueChangShi.vue"),
      },
      {
        path: "/jiChuShuLi",
        name: "jiChuShuLi",
        component: () => import("@/views/channel/yuWen/jiChuShuLi.vue"),
        children: [
          {
            path: "/ziCiRenDu",
            name: "ziCiRenDu",
            component: () => import("@/views/channel/yuWen/ziCiRenDu.vue"),
          },{
            path: "/guJinYiYi",
            name: "guJinYiYi",
            component: () => import("@/views/channel/yuWen/guJinYiYi.vue"),
          },{
            path: "/ciLeiHuoYong",
            name: "ciLeiHuoYong",
            component: () => import("@/views/channel/yuWen/ciLeiHuoYong.vue"),
          },{
            path: "/juShiJiLei",
            name: "juShiJiLei",
            component: () => import("@/views/channel/yuWen/juShiJiLei.vue"),
          },{
            path: "/ciYuLeiJi",
            name: "ciYuLeiJi",
            component: () => import("@/views/channel/yuWen/ciYuLeiJi.vue"),
          },{
            path: "/tongJiaShiYi",
            name: "tongJiaShiYi",
            component: () => import("@/views/channel/yuWen/tongJiaShiYi.vue"),
          },{
            path: "/yiCiDuoYi",
            name: "yiCiDuoYi",
            component: () => import("@/views/channel/yuWen/yiCiDuoYi.vue"),
          },
          {
            path: "/ziXingBianXi",
            name: "ziXingBianXi",
            component: () => import("@/views/channel/yuWen/ziXingBianXi.vue"),
          },
          {
            path: "/ciYiBianXi",
            name: "ciYiBianXi",
            component: () => import("@/views/channel/yuWen/ciYiBianXi.vue"),
          },
          {
            path: "/duoYinDuoYi",
            name: "duoYinDuoYi",
            component: () => import("@/views/channel/yuWen/duoYinDuoYi.vue"),
          },
        ],
      },
      {
        path: "/keWenJingJiang",
        name: "keWenJingJiang",
        component: () => import("@/views/channel/yuWen/keWenJingJiang.vue"),
        children: [
          {
            path: "/yuanWenJieXi",
            name: "yuanWenJieXi",
            component: () => import("@/views/channel/yuWen/yuanWenJieXi.vue"),
          },
          {
            path: "/wenZhangJieGou",
            name: "wenZhangJieGou",
            component: () => import("@/views/channel/yuWen/wenZhangJieGou.vue"),
          },
          {
            path: "/zhuZhiDianJing",
            name: "zhuZhiDianJing",
            component: () => import("@/views/channel/yuWen/zhuZhiDianJing.vue"),
          },
          {
            path: "/xieFaYunYong",
            name: "xieFaYunYong",
            component: () => import("@/views/channel/yuWen/xieFaYunYong.vue"),
          },
        ],
      },
      {
        path: "/yueDuTiSheng",
        name: "yueDuTiSheng",
        component: () => import("@/views/channel/yuWen/yueDuTiSheng.vue"),
      },

      // {
      //   path: "/questionContent",
      //   name: "questionContent",
      //   component: () => import("@/views/channel/questionContent.vue"),
      // },
      {
        path: "/dianLiPoXi",
        name: "dianLiPoXi",
        component:() => import("@/views/channel/components/dianLiPoXi.vue")
      },
      {
        path: "/zhiShiYaoDian",
        name: "zhiShiYaoDian",
        component: () => import("@/views/channel/zhiShiYaoDian.vue"),
      },
      
      {
        path: "/zhiShiXiaoJie",
        name: "zhiShiXiaoJie",
        component: () => import("@/views/channel/zhiShiXiaoJie.vue"),
      },

      
      {
        path: "/tongBuKeTang",
        name: "tongBuKeTang",
        component: () => import("@/views/channel/tongBuKeTang.vue"),
      },
      {
        path: "/dianLiFenXi",
        name: "dianLiFenXi",
        component: () => import("@/views/channel/dianLiFenXi.vue"),
      },
      {
        path: "/kaoNengCeYan",
        name: "kaoNengCeYan",
        component: () => import("@/views/channel/kaoNengCeYan.vue"),
      },
      {
        path: "/zhiShiGouJian",
        name: "zhiShiGouJian",
        component: () => import("@/views/channel/shuXue/zhiShiGouJian.vue"),
      },
      {
        path: "/zhuanTiGuiNa",
        name: "zhuanTiGuiNa",
        component: () => import("@/views/channel/shuXue/zhuanTiGuiNa.vue"),
      },
      {
        path: "/jieTiTongFa",
        name: "jieTiTongFa",
        component: () => import("@/views/channel/shuXue/jieTiTongFa.vue"),
      },

      {
        path: "/keWenLangDu",
        name: "keWenLangDu",
        component: () => import("@/views/channel/yingYu/keWenLangDu.vue"),
      },
      {
        path: "/danCiSuJi",
        name: "danCiSuJi",
        component: () => import("@/views/channel/yingYu/danCiSuJi.vue"),
      },
      {
        path: "/qingJingDuiHua",
        name: "qingJingDuiHua",
        component: () => import("@/views/channel/yingYu/qingJingDuiHua.vue"),
      },
      {
        path: "/gaoPinCiHui",
        name: "gaoPinCiHui",
        component: () => import("@/views/channel/yingYu/gaoPinCiHui.vue"),
      },
      {
        path: "/juShiJiLei",
        name: "juShiJiLei",
        component: () => import("@/views/channel/yingYu/juShiJiLei.vue"),
      },
      {
        path: "/gaoKaoLianJie",
        name: "gaoKaoLianJie",
        component: () => import("@/views/channel/gaoKaoLianJie.vue"),
      },

      {
        path: "/danCi",
        name: "danCi",
        component: () => import("@/views/channel/yingYu/danCi.vue"),
      },
      {
        path: "/yuFaJingJiang",
        name: "yuFaJingJiang",
        component: () => import("@/views/channel/yingYu/yuFaJingJiang.vue"),
      },
      {
        path: "/dongHuaYinBiao",
        name: "dongHuaYinBiao",
        component: () => import("@/views/channel/yingYu/dongHuaYinBiao.vue"),
      },
      
      {
        path: "/xieZuo",
        name: "xieZuo",
        component: () => import("@/views/channel/yingYu/xieZuo.vue"),
        children: [
          {
            path: "/xieZuoZhiDao",
            name: "xieZuoZhiDao",
            component: () => import("@/views/channel/yingYu/xieZuoZhiDao.vue"),
          },
          {
            path: "/changYongBiaoDa",
            name: "changYongBiaoDa",
            component: () => import("@/views/channel/yingYu/changYongBiaoDa.vue"),
          },
          {
            path: "/xieZuoMuBan",
            name: "xieZuoMuBan",
            component: () => import("@/views/channel/yingYu/xieZuoMuBan.vue"),
          },
          {
            path: "/dianLiXiangXi",
            name: "dianLiXiangXi",
            component: () => import("@/views/channel/yingYu/dianLiXiangXi.vue"),
          },
        ],
      },
      

     


    ],
  },
  //在线答疑
  {
    path: "/answerQuestions",
    name: "answerQuestions",
    component: () => import("@/views/answerQuestions/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/complateInfs",
    name: "complateInfs",
    component: () => import("@/views/complateInfs.vue"),
  },

  {
    path: "/sigin",
    name: "sigin",
    component: () => import("@/views/sigin.vue"),
  },

  {
    path: "*",
    name: "errorPage",
    component: () => import("@/views/errorPage.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    history.pushState(null, null, document.URL);
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0,
      };
    }
  },
});
router.afterEach((to, from) => {
  history.pushState(null, null, location.protocol + '//' + location.host + '/#' + to.fullPath)
})

//判断是否登录
router.beforeEach(function (to, from, next) {
  let token = window.localStorage.getItem("token");
  if (to.path != "/login" && to.path != "/sigin") {
    //通过查看token判断是否登录
    if (token && token != "") {
      next(); //表示已经登录
    } else {
      //next可以传递一个路由对象作为参数 表示需要跳转到的页面
      next({
        name: "login",
      });
    }

    if (to.path != "/complateInfs") {
      let isComplated = window.localStorage.getItem("complatedStatus");
      if (isComplated!=0) {
        next(); //表示已经完成完善信息
      } else {
        //next可以传递一个路由对象作为参数 表示需要跳转到的页面
        next({
          name: "complateInfs",
        });
      }
    }
  } else {
    //表示不需要登录
    next(); //继续往后走
  }
});

export default router;
