<template>
	<div class="page-box" ref="content">
		<div class="page-box-con">
			<div class="main-left">
				<el-image fit="cover" :src="logoImg" class="index-logo"></el-image>

				<div class="heard-btn">
				<!-- 	<div class="full-icon cup" @click="pagedyFn">
						在线
						<br />
						答疑
					</div> -->
					<div
						class="full-icon cup"
						:style="`backgroundImage:url(${fullFlag ? exitFull : fsHandle})`"
						@click="fullScreenAction"
					></div>

					<div class="full-icon cup" :style="`backgroundImage:url(${exitIcon})`" @click="exitAction"></div>
				</div>

				<ul class="menu-list" ref="menuList">
					<li
						class="menu-item"
						:class="{ active: item.status }"
						v-for="(item, index) in menuData"
						:key="`menu${index}`"
						@click="selectMenu(item, index)"
					>
						<el-image class="menu-icon" fit="cover" :src="item.iconWhite" v-show="item.status"></el-image>
						<el-image class="menu-icon" fit="cover" :src="item.iconGary" v-show="!item.status"></el-image>
						<span style="font-size: 0.25rem">{{ item.text }}</span>
					</li>
				</ul>
			</div>
			<div class="main-right" ref="mainRight">
				<div class="right-menu"></div>
				<div class="right-con">
					<keep-alive>
						<router-view />
					</keep-alive>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { fullScreen, exitScreen } from '@/utils/public.js';
import { mapGetters } from 'vuex';
export default {
	name: 'indexPage',
	computed: {
		...mapGetters({
			fullFlag: 'common/getFullScreen'
		})
	},
	data() {
		return {
			logoImg: require('@/assets/image/logo.png'),
			userIcon: require('@/assets/image/icon/userIcon.png'),
			fsHandle: require('@/assets/image/icon/fullScreen.png'),
			exitFull: require('@/assets/image/icon/exitFull.png'),
			backPage: require('@/assets/image/icon/backPage.png'),
			exitIcon: require('@/assets/image/icon/exit.png'),
			menuData: [
				{
					text: '首页',
					iconGary: require('@/assets/image/icon/menu_icon0_0.png'),
					iconWhite: require('@/assets/image/icon/menu_icon0_1.png'),
					url: '/',
					status: true
				},
				{
					text: '图书馆',
					iconGary: require('@/assets/image/icon/menu_icon1_0.png'),
					iconWhite: require('@/assets/image/icon/menu_icon1_1.png'),
					url: '/library',
					status: false
				},
				{
					text: '错题本',
					iconGary: require('@/assets/image/icon/menu_icon2_0.png'),
					iconWhite: require('@/assets/image/icon/menu_icon2_1.png'),
					url: '/errorBook',
					status: false
				},
				{
					text: '学习中心',
					iconGary: require('@/assets/image/icon/menu_icon3_0.png'),
					iconWhite: require('@/assets/image/icon/menu_icon3_1.png'),
					url: '/userCenter',
					status: false
				}
			],
			scaleNum: 1,
			screenWidth: 1920,
			screenHeight: 1080,
			marginNum: 50,
			mainRightWidth: 0
		};
	},
	watch: {},
	created() {
		let idx = this.$route.query.indexActive || 0;
		this.setActive(idx);
	},
	activated() {
		let idx = this.$route.query.indexActive || 0;
		this.setActive(idx);
	},
	mounted() {
		this.$eventBus.$on('backIndex', () => {
			this.setActive(0);
		});
	},
	destroyed() {
		this.$eventBus.$off('backIndex');
	},
	methods: {
		fullScreen,
		exitScreen,
		exitAction() {
			this.$confirm();

			let that = this;
			that.$confirm('确定要退出吗？', {
				showClose: false,
				callback: function (val) {
					if (val == 'confirm') {
						that.$api.userLogout().then((res) => {
							if (res.code == 0) {
								window.localStorage.setItem('token', '');
								window.location.reload();
							}
						});
					}
				}
			});
		},
		fullScreenAction() {
			if (this.fullFlag) {
				this.exitScreen(() => {
					this.$store.commit('common/setFullScreen', false);
				});
			} else {
				this.fullScreen(() => {
					this.$store.commit('common/setFullScreen', true);
				});
			}
		},
		selectMenu(item, idx) {
			this.setActive(idx);
			this.$router.replace({
				path: item.url,
				query: {
					indexActive: idx
				}
			});
			this.$eventBus.$emit('changeIndeMenu');
		},
		setActive(idx) {
			this.menuData.forEach((item, index) => {
				item.status = false;
				if (index == idx) {
					item.status = true;
				}
			});
		},
		pagedyFn() {
			this.$router.replace({
				path: '/answerQuestions'
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.heard-btn {
	position: fixed;
	right: 0;
	top: 0;
	padding: 0.5rem;
	z-index: 1000;
	display: flex;
	align-items: center;
	transform-origin: right top;

	.full-icon {
		width: 0.64rem;
		height: 0.64rem;
		margin-left: 0.1rem;
		background-size: cover;
		border-radius: 50%;
		background-color: #fff;
		font-size: 0.16rem;
		color:#333333;
		display: flex;
		align-items: center;
		justify-content: center;
		&:hover {
			opacity: 0.8;
		}
	}
}

.page-box {
	display: flex;
	justify-content: flex-start;
	transform-origin: 0 0;

	.page-box-con {
		width: 100vw;
		height: 100vh;
		display: flex;
	}

	.main-left {
		display: flex;
		flex-direction: column;
		width: 2.54rem;
		min-width: 2.54rem;
		margin-right: 0.32rem;
		transform-origin: left top;
		color: #fff;
		margin: 0.5rem 0.32rem 0.5rem 0.5rem;

		.index-logo {
			width: 6.7rem;
			height: 0.65rem;
			min-height: 0.65rem;
			margin-bottom: 0.67rem;
		}

		.menu-list {
			display: flex;
			justify-content: center;
			flex-direction: column;
			flex: 1;
			background: #ffffff;
			border-radius: 0.2rem;
			width: 100%;
			user-select: none;

			li {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 1.6rem;
				font-size: 0.24rem;
				color: #333;
				cursor: pointer;

				.menu-icon {
					margin-bottom: 0.2rem;
					width: 0.5rem;
					height: 0.5rem;
				}

				&:hover {
					opacity: 0.8;
				}
			}

			.active {
				color: #fff;
				background: #ffa800;
				border-radius: 0.1rem;
			}
		}
	}

	.main-right {
		flex: 1;
		display: flex;
		flex-direction: column;
		transform-origin: left top;
		overflow: hidden;
		margin: 0.5rem 0.5rem 0.5rem 0;

		.right-menu {
			height: 0.81rem;
			min-height: 0.81rem;
			margin-bottom: 0.47rem;
		}

		.right-con {
			flex: 1;
			background: #ffffff;
			border-radius: 0.2rem;
			overflow: hidden;
		}
	}
}
</style>
