<template>
<div class="loading-box">
<div>
<i class="el-icon-loading"></i>
</div>
</div>
</template>

<script>
export default {
name: "loadingBox",
};
</script>

<style lang="scss" scoped>
.loading-box{
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
width: 100%;
height: 100%;
z-index: 100;
display: flex;
justify-content: center;
align-items: center;
background: rgba(255,255,255,.5);
i{
font-size: .4rem;
color: #28b295;
}
}
</style>