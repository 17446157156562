

import http from "../http";

const user = {
 
  //获取科目接口
  getSubject: function (params) {
    return http.get("/api/v2/subject", params);
  },
 
  //获取历史记录接口
  getHistory: function (params) {
    return http.get("/api/v2/learningrecord", params);
  },

  //科目列表接口
  getcoursetypelist: function (params) {
    return http.get("/api/v2/wenda/getcoursetypelist", params);
  },
  //科目问答列表接口
  getwendalist: function (params) {
    return http.get("/api/v2/wenda/getwendalist", params);
  },
  //浏览量接口
  getaddviewnum: function (params) {
    return http.get("/api/v2/wenda/addviewnum", params);
  },
  //我回复的帖子接口
  getmyhuifulist: function (params) {
    return http.get("/api/v2/wenda/getmyhuifulist", params);
  },
  //发布问答接口
  getaddwenti: function (params) {
    return http.post("/api/v2/wenda/addwenti", params);
  },
  //回帖接口
  getaddhuida: function (params) {
    return http.post("/api/v2/wenda/addhuida", params);
  },
  //我回复的帖子删除接口
  getwendadeltie: function (params) {
    return http.get("/api/v2/wenda/deltie", params);
  },
};

export default user;
