import http from "../http"
const menu = {
  //获取目录
  getMenuById: function (params) {
    return http.get("/api/v2/courses", params)
  },
  //获取目录下的内容
  getMenuContent: function (params) {
    return http.get("/api/v2/coursecontent", params)
  },

  //获取目录下的题目
  getMenuQuestion: function (params) {
    return http.get("/api/v2/questionbank", params)
  },
  //获取目录下的题目 ==> 高频词汇
  getGaoPinCiHui: function (params) {
    return http.get("/api/v2/getgaopincihui", params)
  },

  //获取作者简介
  getMenuAuthor: function (params) {
    return http.get("/api/v2/getzzjj", params)
  },

  //获取名家朗读
  getMenuMjld: function (params) {
    return http.get("/api/v2/mjld", params)
  },

  //获取单词
  getWords: function (params) {
    return http.get("/api/v2/word", params)
  },

  //获取真题
  getZhenTi: function (params) {
    return http.get("/api/v2/truetopic", params)
  },

  //获取用户选择的版本
  getUserPress: function (params) {
    return http.get("/api/v2/pressuser", params)
  },
  getNewMenuContent: function (params) {
    return http.get("/api/v2/getsytj", params)
  },
  getSwf: function (url, params, obj) {
    return http.get(url, params, obj)
  },
}

export default menu
