import {
	Notification,
	MessageBox,
	Message,
	Loading
} from "element-ui";
//import Vue from '../main.js'
export function setSourceUrl(str) {
	return `https://aixuebang-1310518323.file.myqcloud.com/${str}`;
}

function randomNum(min, max) {
	return parseInt(Math.random() * (max - min) + min);
}
export function getRandom(min, max, num) {
	let _num = randomNum(min, max);
	if (_num == num) {
		_num = getRandom(min, max, num);
	}
	return _num;
}
export function formatTime(time) {
	time = parseInt(time);
	let hour = 0,
		minute = 0,
		second = 0;
	hour = parseInt(time / 3600);
	minute = parseInt((time - hour * 3600) / 60);
	second = time - hour * 3600 - minute * 60;
	hour = hour < 10 ? `0${hour}` : hour;
	minute = minute < 10 ? `0${minute}` : minute;
	second = second < 10 ? `0${second}` : second;
	return `${hour}:${minute}:${second}`;
}
export function acountTime() {
	clearInterval(this.timer);
	let i = 0;
	this.timer = setInterval(() => {
		i++;
		let second = i % 60 < 10 ? `0${i % 60}` : i % 60;
		let minute =
			parseInt(i / 60) < 10 ? `0${parseInt(i / 60)}` : parseInt(i / 60);
		return `${minute}:${second}`;
	}, 1000);
}
export function computeQuestion(str, tags = ["（", "）"]) {
	let reg1 = new RegExp(`${tags[0]}`, "g");
	let reg2 = new RegExp(`${tags[1]}`, "g");
	let _str1 = str.replace(reg1, "|");
	let _str2 = _str1.replace(reg2, "|");
	let _str3 = _str2.split("</p>");

	let arr = [];
	let arr1 = [];

	for (let i = 0; i < _str3.length - 1; i++) {
		let _s = _str3[i].replace(/<\/?.+?>/g, "").replace(/ /g, "");
		arr.push(_s);
	}

	let _qnum = 0;
	for (let i = 0; i < arr.length; i++) {
		let sarr = arr[i].split("|");
		let iarr = [];
		for (let j = 0; j < sarr.length; j++) {
			let _i = {
				status: false,
				text: sarr[j].replace("@", ""),
			};
			if (-1 < sarr[j].indexOf("@")) {
				_i.recite = true;
				_i.isRecite = true;
				_i.qnum = ++_qnum;
			} else {
				_i.recite = false;
				_i.isRecite = false;
			}
			iarr.push(_i);
		}
		arr1.push(iarr);
	}
	return arr1;
}

// 防抖
export function debounce(fun, delay) {
	return function(args) {
		let that = this;
		let _args = args;
		clearTimeout(fun.id);
		fun.id = setTimeout(function() {
			fun.call(that, _args);
		}, delay);
	};
}
// 节流
export function throttle(fun, delay) {
	let last, deferTimer;
	return function(args) {
		let that = this;
		let _args = args;
		let now = +new Date();
		if (last && now < last + delay) {
			clearTimeout(deferTimer);
			deferTimer = setTimeout(function() {
				last = now;
				fun.apply(that, _args);
			}, delay);
		} else {
			last = now;
			fun.apply(that, _args);
		}
	};
}

export function setMathBa(str) {
	str = str || "";
	str = str.replace(
		new RegExp("&lt;x拔&gt;", "g"),
		'<span class="math-ba">x</span>'
	);
	return str;
}

export function html2string(html) {
	html = String(html);
	return html.replace(/<\/?.+?>/g, "").replace(/ /g, "");
}
export function removeSpace(str) {
	if (!str) {
		str = "";
	}
	str = str.replace(/\s*/g, "");
	return str;
}
export const questionType = {
	1: "单选题",
	2: "多选题",
	3: "判断题",
};

export const setQuestion = [
	"课后练兵",
	"单元检测",
	"期中考试",
	"期中测试",
	"期末考试",
	"期末测试",
	"精选压轴题",
];

export function deepClone(target) {
	function clone(target, map = new WeakMap()) {
		let result;
		const type = Object.prototype.toString.call(target).match(/\s(\w+)\]/)[1];

		if (typeof target !== "object" || target === null) {
			// 基本数据类型
			result = target;
		} else {
			if (["Array", "Set", "Map", "Object", "Arguments"].includes(type)) {
				// 可递归遍历的类型处理
				// 循环引用处理
				if (map.get(target)) {
					result = map.get(target);
				} else {
					const Constr = target.constructor;
					result = new Constr();
					map.set(target, result);

					if (type === "Array") {
						// Array
						target.forEach((v) => {
							result.push(clone(v, map));
						});
					} else if (type === "Set") {
						// Set
						target.forEach((v) => {
							result.add(clone(v, map));
						});
					} else if (type === "Map") {
						// Map
						target.forEach((v, k) => {
							result.set(k, clone(v, map));
						});
					} else {
						// Object Arguments
						Object.keys(target).forEach((k) => {
							result[k] = clone(target[k], map);
						});
					}
				}
			} else {
				// 不可递归遍历的类型处理
				const Constr = target.constructor;
				if (type === "RegExp") {
					// RegExp
					result = new Constr(target.source, /\w*$/.exec(target));
					result.lastIndex = target.lastIndex;
				} else if (type.includes("Function")) {
					// Function AsyncFunction GeneratorFunction
					result = target;
				} else if (["Date"].includes(type)) {
					// Date
					result = new Constr(target);
				} else if (type === "Error") {
					// Error
					result = new Constr(target.message);
					result.stack = target.stack;
				} else if (type === "URL") {
					// URL
					result = new Constr(target.href);
				} else if (type.includes("Array")) {
					// ArrayBuffer TypeArray BigArray ...
					result = target.slice();
				} else if (type === "DataView") {
					// DataView
					result = new Constr(
						target.buffer.slice(0),
						target.byteOffset,
						target.byteLength
					);
				} else {
					try {
						// 包装过的 Number String Symbol BigInt
						const val = Constr.prototype.valueOf.call(target);
						result = Object(val);
					} catch (err) {
						// other
						console.warn(`Uncatched type：${type}`);
						console.warn(err);
					}
				}
			}
		}
		return result;
	}

	const res = clone(target);
	return res;
}

export function customMessage(message, type = "warning") {
	let dom = document.getElementsByClassName("el-message")[0];
	if (!dom) {
		Message({
			type: type,
			message: message,
		});
	}
}

export function fullScreen(callback) {
	let el = document.documentElement;
	let rfs =
		el.requestFullscreen ||
		el.webkitReQuestFullscreen ||
		el.msRequestFullscreen;
	if (typeof rfs != "undefined" && rfs) {
		rfs.call(el);
	}
	if (callback) {
		callback();
	}
	return;
}
export function exitScreen(callback) {
	let el = document.documentElement;
	let cfs =
		el.requestFullscreen ||
		el.webkitReQuestFullscreen ||
		el.msRequestFullscreen;
	if (document.exitFullscreen) {
		document.exitFullscreen();
	} else if (document.mozCancelFullScreen) {
		document.mozCancelFullScreen();
	} else if (document.webkitCancelFullScreen) {
		document.webkitCancelFullScreen();
	} else if (document.msExitFullScreen) {
		document.msExitFullScreen();
	}
	if (typeof cfs != "undefined" && cfs) {
		cfs.call(el);
	}
	if (callback) {
		callback();
	}
}

export function fullStatus() {
	return !!(
		document.fullScreen ||
		document.mozFullScreen ||
		document.webkitlsFullScreen ||
		document.webkitFullScreen ||
		document.msFullScreen
	);
}

export function getNowTime() {
	let date = new Date();
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours();
	let minute = date.getMinutes();
	let second = date.getSeconds();
	let time = `${year}.${addZero(month)}.${addZero(day)} ${addZero(
    hour
  )}:${addZero(minute)}:${addZero(second)}`;

	return time;
}

function addZero(s) {
	return s < 10 ? "0" + s : s;
}

export function computeOptionjson(str, tag) {
	//格式化 选择题选项
	if (str) {
		let optionsValues = [
			"A",
			"B",
			"C",
			"D",
			"E",
			"F",
			"G",
			"H",
			"I",
			"J",
			"K",
			"L",
			"M",
			"N",
			"O",
			"P",
			"R",
			"S",
			"T",
			"U",
			"V",
			"W",
			"X",
			"Y",
			"Z",
		];
		let option = [];
		let optionjson = str.match(/<li>(.+?)<\/li>/g);
		if (tag && tag != "") {
			tag = tag.replace(/\s+/g, "");
			if (optionjson) {
				Object.keys(optionjson).forEach((item) => {
					let text = optionjson[item];
					text = text.replace(new RegExp("<li>", "g"), "");
					text = text.replace(new RegExp("</li>", "g"), "");
					option.push({
						text: middleImg(formatMathJaxImg(text)),
						value: setMathBa(optionsValues[item]),
						status: 0,
					});
				});
			}
		} else {
			if (optionjson) {
				Object.keys(optionjson).forEach((item) => {
					let text = optionjson[item];
					text = text.replace(new RegExp("<li>", "g"), "");
					text = text.replace(new RegExp("</li>", "g"), "");
					option.push({
						text: middleImg(formatMathJaxImg(text)),
						value: optionsValues[item],
						status: 0,
					});
				});
			}
		}

		return option;
	}
}

function CheckChinese(val) {
	var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
	if (reg.test(val)) {
		return true;
	} else {
		return false;
	}
}
export function formatAddPointWords(val) {
	let content = val || "";
	if (-1 < content.indexOf("<em>") && -1 < content.indexOf("</em>")) {
		let mobiaozi = content.match(/<em>[\s\S]*?<\/em>/gi);
		for (let i = 0; i < mobiaozi.length; i++) {
			let word = mobiaozi[i];
			word = word.replace(new RegExp("<em>", "g"), "");
			word = word.replace(new RegExp("</em>", "g"), "");
			let str = "";
			for (let j = 0; j < word.length; j++) {
				if (CheckChinese(word[j])) {
					str = str + `<span class="point-word">${word[j]}</span>`;
				} else {
					str = str + `${word[j]}`;
				}
			}
			content = content.replace(mobiaozi[i], str);
		}
	}

	return content;
}

function customReplace(str, source, target, idx) {
	let count = 0;
	return str.replace(new RegExp(source, "g"), (p) => {
		count++;
		if (count == idx) {
			return target;
		} else {
			return source;
		}
	});
}

export function checkPhone(tel) {
	let telReg = /^1[3-9]\d{9}$/;
	if (!tel) {
		return false;
	}
	if (!telReg.test(tel)) {
		return false;
	} else {
		return true;
	}
}

/* export function formatMathJaxImg(str) {
  if (!str) {
    return false;
  }
  let imgReg = /<img.*?(?:>|\/>)/gi;
  let arr = str.match(imgReg);
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      if (-1 < item.indexOf("data-latex")) {
        let dataReg = /data-latex=['"]?([^'"]*)['"]?/i;
        let latex = item.match(dataReg)[1];
        str = str.replace(
          item,
          '<span class="math-formula" id="math-formula' +
            i +
            '" style=" display: inline-flex; padding-right:.04rem;">$$$$' +
            latex +
            "$$$$</span>"
        );
      }
    }
  }

  return str;
} */
export function formatMathJaxImg(str) {
	if (!str) {
		return false;
	}
	let imgReg = /<img.*?(?:>|\/>)/gi;
	let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
	let arr = str.match(imgReg);

	if (arr) {
		for (let i = 0; i < arr.length; i++) {
			let item = arr[i];
			if (-1 < item.indexOf("data-latex")) {
				let dataReg = /data-latex=['"]?([^'"]*)['"]?/i;
				let latex = item.match(dataReg)[1];
				var htmlString = item.match(dataReg).input
				var regex = /data-latex="([^"]*)"/;
				var match = regex.exec(htmlString);
				var newMatch = ''
				if (match) {
					newMatch = match[1];
				} else {
					console.log("No match found.");
				}
				str = str.replace(
					item,
					'<span class="math-formula" id="math-formula' +
					i +
					'" style=" display: inline-flex; padding-right:.04rem;">$$$$' +
					newMatch +
					"$$$$</span>"
				);
			}
		}
	}

	return str;
}

//6.25
export function MathJaxLabelImg(str) {
	if (!str) {
		return false;
	}
	let imgReg = /<img.*?(?:>|\/>)/gi;
	let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
	let arr = str.match(imgReg);
	if (arr) {
		for (let i = 0; i < arr.length; i++) {
			let item = arr[i].match(srcReg);

			let dataReg = /data-latex=['"]?([^'"]*)['"]?/i;


			if (arr[i].includes('data-latex')) {
				var htmlString = arr[i].match(dataReg).input


				var regex = /data-latex="([^"]*)"/;
				var match = regex.exec(htmlString);
				var newMatch = ''

				if (match) {
					newMatch = match[1];
				} else {
					console.log("No match found.");
				}
				str =
					`<span class="math-formula new-labal-box" id="math-formula' style="display: inline-flex; padding-right:.04rem;">$$${newMatch}$$</span>`
			}
		}
	}

	return str;
}

export function middleImg(str) {
	let content = String(str || "");
	let content1 = content.replace(/&lt;cimg&gt;/g, '<span class="cimg">');
	let content2 = content1.replace(/&lt;\/cimg&gt;/g, "</span>");

	return content2;
}
export function palindrome(str) {
	let reg = new RegExp(/([,.!?，。！？])/, "g");
	if (reg.test(str)) {
		return false;
	} else {
		return true;
	}
}

export function flattenArray(arr) {
	const flattened = [].concat(...arr);
	return flattened.some((item) => Array.isArray(item)) ?
		flattenArray(flattened) :
		flattened;
}

export function matchKeyWordToInput(str) {
	str = str || "";
	let targetStr = [];
	let newStr = str;
	// targetStr = str.match(/(?<=(<em[^>]*?>)).*?(?=(<\/em>))/g);
	// newStr = str.replace(/(?<=(<em[^>]*?>)).*?(?=(<\/em>))/g, "<input/>");
	targetStr = str.match(/<em>(.*?)<\/em>/g).map(function(val) {
		return val.replace(/<\/?em>/g, '');
	});
	newStr = str.replace(/<em>(.*?)<\/em>/g, "<input/>");
	return {
		arr: targetStr,
		str: newStr,
	};
}

export function matchKeyWordToView(str) {
	str = str || "";
	str = str.replace(/<em>/g, "|{");
	str = str.replace(/<\/em>/g, "}|");
	str = str.replace(/&ldquo;/g, "“");
	str = str.replace(/&hellip;/g, "…");
	str = str.replace(/&rdquo;/g, "”");
	str = html2string(str);
	let arr = str.split("|");
	// debugger
	let resultArr = [];
	for (let i = 0; i < arr.length; i++) {
		let _arr = [];
		if (arr[i].includes("{")) {
			let emStr = arr[i].replace(/{/g, "<em>");
			emStr = emStr.replace(/}/g, "</em>");
			let emStrNew = emStr || "";
			emStrNew = emStrNew.replace(/<em>/g, "");
			emStrNew = emStrNew.replace(/<\/em>/g, "");
			if (emStrNew.length > 1) {
				for (let i = 0; i < emStrNew.length; i++) {
					resultArr = [...resultArr, `<em>${emStrNew[i]}</em>`];
				}
			} else {
				resultArr = [...resultArr, emStr];
			}
		} else {
			_arr = arr[i].split("");
			resultArr = [...resultArr, ...arr[i]];
		}
	}
	return resultArr;
}

let optionsValues = [
	"A",
	"B",
	"C",
	"D",
	"E",
	"F",
	"G",
	"H",
	"I",
	"J",
	"K",
	"L",
	"M",
	"N",
	"O",
	"P",
	"R",
	"S",
	"T",
	"U",
	"V",
	"W",
	"X",
	"Y",
	"Z",
];
export function setOptions(str, hanndle) {
	str = str || "";
	str = str.replace(/<ol>/g, "");
	str = str.replace(/<\/ol>/g, "");
	str = str.replace(/<ul>/g, "");
	str = str.replace(/<\/ul>/g, "");
	str = str.replace(/<li>/g, "|");
	str = str.replace(/<\/li>/g, "");
	str = str.replace(/\r|\n/gi, "");
	if (hanndle) {
		str = str.replace(/\\n/gi, "");
		str = str.replace(/"/g, "");
		str = html2string(str);
	}
	let boxArr = str.split("|");
	let textArr = [];
	let resultArr = [];
	for (let i = 0; i < boxArr.length; i++) {
		if (boxArr[i]) {
			textArr.push(boxArr[i]);
		}
	}
	for (let i = 0; i < textArr.length; i++) {
		resultArr.push({
			text: textArr[i],
			val: optionsValues[i],
			status: 0,
		});
	}
	return resultArr;
}