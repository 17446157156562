import Vue from 'vue'
import App from './App.vue'
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'

//样式
// import 'tinymce/skins/content/default/content.min.css'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'

//主题
import 'tinymce/themes/silver'

//插件
import 'tinymce/plugins/link' //链接插件
import 'tinymce/plugins/image' //图片插件
import 'tinymce/plugins/media' //媒体插件
import 'tinymce/plugins/table' //表格插件
import 'tinymce/plugins/lists' //列表插件
import 'tinymce/plugins/quickbars' //快速栏插件
import 'tinymce/plugins/fullscreen' //全屏插件
import 'tinymce/plugins/hr' //分割线
import 'tinymce/plugins/charmap' //特殊符号
//import 'tinymce/plugins/indent2em' //首行缩进//  3.13注释
import 'tinymce/plugins/preview' //预览 
import 'tinymce/plugins/code' //预览 
//import 'tinymce/plugins/importword' //导入word文档  //3.13注释

/**
 * 注：
 * 5.3.x版本需要额外引进图标，没有所有按钮就会显示not found
 */
import 'tinymce/icons/default/icons'

//本地化
import './utils/tinymce/langs/zh_CN.js'


import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
import api from "@/api/index.js"
import ElementUI from 'element-ui'
import './theme/element/index.css'


import scrollBox from '@/components/scrollBox'
import loadingBox from "@/components/loadingBox";
import coustmEmpty from "@/components/coustmEmpty.vue";
// import mixin from "@/mixins/index"
import { customMessage } from "@/utils/public";
import globalMethods from "@/mixins/globalMethods"
import MathJax from '@/utils/MathJax.js'
Vue.prototype.MathJax=MathJax;
import device from 'current-device'
if (device.mobile()) {
  console.log('移动手机')
  window.location.href="https://gzm.jingdian985.com/#/";
} else if (device.ipad()) {
  console.log('ipad端')
  window.document.title="精准学移动端"
} else if (device.desktop()) {
  console.log('桌面端')
}
// 禁止浏览器前进后退 另一部本在router的index.js中
window.addEventListener('popstate', function() {
  history.pushState(null, null, document.URL)
})
  //屏蔽右键防止视频被下载
  document.addEventListener('contextmenu', e => e.preventDefault());
Vue.mixin(globalMethods)
// Vue.mixin(mixin)
Vue.use(VueAxios, axios)
Vue.use(ElementUI);
Vue.use(VueTinymce)
Vue.prototype.$tinymce = tinymce
Vue.prototype.$axios = axios
Vue.prototype.$api = api
Vue.prototype.$eventBus = new Vue()
Vue.prototype.$customMessage = customMessage

Vue.component('scrollBox',scrollBox)
Vue.component('loadingBox', loadingBox)
Vue.component('coustmEmpty', coustmEmpty)

window.addEventListener('popstate', function() {
  history.pushState(null, null, document.URL)
})
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
